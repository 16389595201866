/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Box, Container, Heading, jsx, Spinner } from 'theme-ui'
import {
  useCheckout,
  useTranslate,
  useUser,
} from '@chordcommerce/gatsby-theme-autonomy'
import GoaffproConversionTracking from '~/components/Goaffpro/ConversionTracking'
import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt'
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary'
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details'
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact'
import { useReferrals } from '~/hooks/components/use-referrals'
import { useRequestReviews } from '~/hooks/components/use-request-reviews'
import { subscribeTransactionalNumber } from '~/services/attentive/client'
import { addCartToKlaviyoLists } from '~/services/klaviyo/client'
import { createShareasaleOrder } from '~/services/shareasale/client'
import { getDoesCartHaveClubItems } from '~/utils/subscriptions'

const OrderConfirmationPage = ({ orderNumber, checkoutSessionId }) => {
  const { loadUserReferralIdentifier } = useUser()
  const { finalizeCheckout } = useCheckout()
  const { setReferralsHasPurchased } = useReferrals()
  const { createYotpoOrder } = useRequestReviews()
  const [cart, setCart] = useState(null)
  const [referralPurl, setReferralPurl] = useState(null)
  const [isClub, setIsClub] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const translate = useTranslate()

  useEffect(() => {
    const finalizeCheckoutAsync = async () => {
      try {
        const finalizedCart = await finalizeCheckout({
          orderNumber,
          checkoutSessionId,
        })

        setCart(finalizedCart)
        setIsLoading(false)

        return finalizedCart
      } catch (error) {
        setError(error)
      }
    }

    if (!cart)
      finalizeCheckoutAsync()
        .then((cart) => {
          setIsClub(getDoesCartHaveClubItems(cart?.lineItems))
          addCartToKlaviyoLists(cart)
          createYotpoOrder(cart)
          subscribeTransactionalNumber(cart?.email)
          createShareasaleOrder(cart?.number)
          setReferralsHasPurchased(true)
        })
        .catch((error) => {
          setError(error)
        })
  }, [cart, checkoutSessionId, orderNumber]) // eslint-disable-line

  useEffect(() => {
    if (cart?.email && !referralPurl) {
      loadUserReferralIdentifier({ email: cart.email })
        .then(({ purl }) => setReferralPurl(purl))
        .catch((e) => console.warn('could not load user purl', e))
    }
  }, [loadUserReferralIdentifier, cart?.email, referralPurl])

  if (error) return <ConfirmationError error={error} />
  if (isLoading || !cart) return <ConfirmationLoading />

  const { number } = cart

  return (
    <ConfirmationContainer cart={cart} isClub={isClub}>
      <Heading
        as="h2"
        mb={[5, 7]}
        variant="title5"
        sx={{ textAlign: 'center' }}
      >
        {translate('confirmation.text', { number: number })}
      </Heading>
      <Box>
        <Box
          mb={3}
          mr={[null, null, '12px']}
          sx={{
            float: [null, null, 'left'],
            width: [null, null, 'calc(58.3% - 12px)'],
          }}
        >
          <OrderConfirmationDetails cart={cart} isClub={isClub} />
        </Box>
        <Box
          mb={3}
          ml={[null, null, '12px']}
          sx={{
            float: [null, null, 'right'],
            width: [null, null, 'calc(41.7% - 12px)'],
          }}
        >
          <OrderConfirmationSummary cart={cart} />
        </Box>
        {referralPurl && (
          <Box
            mb={[3, null, 0]}
            mr={[null, null, '12px']}
            sx={{
              float: [null, null, 'left'],
              width: [null, null, 'calc(58.3% - 12px)'],
            }}
          >
            <OrderConfirmationReferralPrompt purl={referralPurl} />
          </Box>
        )}
        <Box
          mb={[3, null, 0]}
          ml={[null, null, '12px']}
          sx={{
            clear: [null, null, 'right'],
            float: [null, null, 'right'],
            width: [null, null, 'calc(41.7% - 12px)'],
          }}
        >
          <OrderConfirmationContact />
        </Box>
        <Box sx={{ clear: 'both' }} />
      </Box>
      {cart && <GoaffproConversionTracking order={cart} />}
    </ConfirmationContainer>
  )
}

const ConfirmationLoading = () => {
  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          py={7}
          sx={{
            textAlign: 'center',
          }}
        >
          <Spinner
            size="100"
            color="brandTertiary"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationError = () => {
  const translate = useTranslate()

  return (
    <ConfirmationContainer loaded={false}>
      <Heading
        as="h1"
        mb={2}
        variant="title2"
        sx={{
          textAlign: 'center',
        }}
      >
        {translate('error.api.default')}
      </Heading>
    </ConfirmationContainer>
  )
}

const ConfirmationContainer = ({
  children,
  cart = {},
  isClub,
  loaded = true,
}) => {
  const translate = useTranslate()
  const name = cart.billAddress && cart.billAddress.name

  return (
    <Box pt={[7, 9]} pb={[7, 14]}>
      <Container>
        {loaded && (
          <Heading
            as="h1"
            mb={2}
            variant="title2"
            sx={{
              textAlign: 'center',
            }}
          >
            {!isClub && `${translate('confirmation.title')}, ${name}!`}
            {isClub && `Welcome to the Can Club, ${name}!`}
          </Heading>
        )}
        {children}
      </Container>
    </Box>
  )
}

export default OrderConfirmationPage
