/** @jsx jsx */
import { Fragment, useEffect } from 'react'
import { jsx } from 'theme-ui'
import { getGoaffproOrder } from '~/services/goaffpro/utils'
import { isProduction } from '~/utils/misc'

const GoaffproConversionTracking = ({ order }) => {
  useEffect(() => {
    if (
      typeof window.goaffproTrackConversion !== 'undefined' &&
      order.number &&
      isProduction()
    ) {
      const goaffproOrder = getGoaffproOrder(order)
      window.goaffproTrackConversion(goaffproOrder)
    }
  }, []) // eslint-disable-line

  return <Fragment />
}

export default GoaffproConversionTracking
