/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Heading, jsx, Text } from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import SummaryItems from '~/components/Order/Confirmation/SummaryItems'
import { useTranslate, utils } from '@chordcommerce/gatsby-theme-autonomy'

const OrderConfirmationSummary = ({ cart = {} }) => {
  const {
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    displayTotalApplicableStoreCredit,
    displayOrderTotalAfterStoreCredit,
    giftNote,
    lineItems,
  } = cart

  const { getAllCartPromotionsForDisplay } = utils
  const promotions = getAllCartPromotionsForDisplay(cart)
  const translate = useTranslate()

  return (
    <Box backgroundColor="white">
      <Heading
        as="h3"
        variant="title5"
        backgroundColor="backgroundMedium"
        px={3}
        py={2}
      >
        {translate('confirmation.summary.title')}
      </Heading>
      <Box px={3}>
        <LineItems items={lineItems} orderConfirmed={true} />
      </Box>
      {giftNote?.note && (
        <Box
          mx={3}
          py={2}
          sx={{ borderBottom: '1px solid', borderColor: 'greyLight' }}
        >
          <Text as="p" m={0} mb={1} variant="body3" sx={{ fontWeight: 'bold' }}>
            {`${translate('confirmation.summary.gift')}:`}
          </Text>
          <Text as="p" m={0} variant="description" color="greyDark">
            {giftNote?.note}
          </Text>
        </Box>
      )}
      <Box px={3} py={2}>
        <SummaryItems
          displayItemTotal={displayItemTotal}
          displayTaxTotal={displayTaxTotal}
          displayShipTotal={displayShipTotal}
          displayTotal={displayTotal}
          displayTotalApplicableStoreCredit={displayTotalApplicableStoreCredit}
          displayOrderTotalAfterStoreCredit={displayOrderTotalAfterStoreCredit}
          promotions={promotions}
        />
      </Box>
    </Box>
  )
}

OrderConfirmationSummary.propTypes = {
  cart: PropTypes.shape({
    displayTotal: PropTypes.string,
    displayItemTotal: PropTypes.string,
    displayTaxTotal: PropTypes.string,
    displayShipTotal: PropTypes.string,
  }),
}

export default OrderConfirmationSummary
