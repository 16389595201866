/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import copy from 'copy-to-clipboard'
import { Box, Button, Flex, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'

const AccountReferralForm = ({ purl = '' }) => {
  const translate = useTranslate()
  const { register, getValues } = useForm({ defaultValues: { purl } })
  const [copied, setCopied] = useState(false)

  const onCopy = (e) => {
    e.preventDefault()
    const values = getValues()
    copy(values.purl)
    setCopied(true)
  }

  return (
    <Flex as="form" sx={{ flexDirection: ['column', 'row'] }}>
      <Box mb={[2, 0]} mr={[null, 2]} sx={{ flex: '1' }}>
        <FormInputWithLabel
          ref={register}
          label={translate('referral.copy_link_input_label')}
          title={translate('referral.copy_link_input_label')}
          aria-label={translate('referral.copy_link_input_label')}
          name="purl"
          readOnly={true}
        />
      </Box>
      <Button onClick={onCopy} disabled={copied} px={4}>
        {copied
          ? translate('referral.copy_link_button_clicked')
          : translate('referral.copy_link_button')}
      </Button>
    </Flex>
  )
}

export default AccountReferralForm
