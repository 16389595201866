/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Grid, Heading, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import AttentiveOptIn from '~/components/Attentive/OptIn'

const OrderConfirmationDetails = ({ cart = {}, isClub }) => {
  const translate = useTranslate()
  const { email, payments, shipAddress } = cart

  const validPayments = payments.filter(
    ({ displayAmount, state }) =>
      state !== 'invalid' && displayAmount !== '$0.00'
  )

  return (
    <Box backgroundColor="white">
      <Heading
        as="h3"
        variant="title5"
        backgroundColor="backgroundMedium"
        px={3}
        py={2}
      >
        {translate('confirmation.details.title')}
      </Heading>
      <Box p={3}>
        <Box
          dangerouslySetInnerHTML={{
            __html: translate(
              `confirmation.details.text.${isClub ? 'club' : 'default'}`,
              {
                email: `<strong>${email}</strong>`,
              }
            ),
          }}
          variant="text.body2"
          sx={{ p: { m: 0, mb: 2 } }}
        />
        {cart?.metadata?.mwcGwgwAddress && (
          <Box
            dangerouslySetInnerHTML={{
              __html: translate(`confirmation.details.text.gwgw`, {
                gwgwAddress: `<strong>${cart?.metadata?.mwcGwgwAddress}</strong>`,
              }),
            }}
            variant="text.body2"
            sx={{ p: { m: 0, mb: 3 } }}
          />
        )}
        <AttentiveOptIn emailAddress={email} />
        <Grid
          columns={[1, 2]}
          mt={3}
          pt={3}
          sx={{
            borderTop: '1px solid',
            borderTopColor: 'greyLight',
          }}
        >
          <Box>
            <Text as="p" variant="body2" mb={1} sx={{ fontWeight: 'bold' }}>
              {translate('confirmation.details.shipping')}:
            </Text>
            <Text as="p" variant="body2">
              {shipAddress.fullName}
            </Text>
            <Text as="p" variant="body2">
              {shipAddress.address1}
            </Text>
            {shipAddress.address2 && (
              <Text as="p" variant="body2">
                {shipAddress.address2}
              </Text>
            )}
            <Text as="p" variant="body2">
              {shipAddress.city}, {shipAddress.stateText}, {shipAddress.zipcode}
            </Text>
          </Box>

          {validPayments && validPayments.length > 0 && (
            <Box>
              <Text as="p" variant="body2" mb={1} sx={{ fontWeight: 'bold' }}>
                {translate('confirmation.details.payment')}:
              </Text>

              {validPayments.map((payment) => {
                if (payment.sourceType === 'Spree::CreditCard') {
                  return (
                    <Text
                      key={payment.id}
                      as="p"
                      variant="body2"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {payment.source.ccType} (**** {payment.source.lastDigits})
                    </Text>
                  )
                }
                return null
              })}
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

OrderConfirmationDetails.propTypes = {
  cart: PropTypes.shape({
    email: PropTypes.string,
    number: PropTypes.string,
    payments: PropTypes.array,
    shipAddress: PropTypes.shape({}),
  }),
}

export default OrderConfirmationDetails
