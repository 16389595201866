// @see https://github.com/anujtenani/goaffpro/wiki/Custom-Integration-advanced-guide
export const getGoaffproOrder = (order) => {
  const lineItems = []
  const promoCodes = []

  order.lineItems
    .filter((lineItem) => !lineItem?.variant?.hidden)
    .forEach((lineItem) => {
      lineItems.push({
        // Goaffpro's calculation for commission: (price * quantity) - discount.
        discount: lineItem.adjustments.reduce(
          (totalDiscount, adjustment) =>
            adjustment.sourceType === 'Spree::PromotionAction' &&
            adjustment.eligible
              ? totalDiscount + Math.abs(adjustment.amount)
              : totalDiscount,
          0
        ),
        name: lineItem.variant.name,
        price: parseFloat(lineItem.price),
        quantity: lineItem.quantity,
        sku: lineItem.variant.sku,
      })

      lineItem.adjustments.forEach((adjustment) => {
        if (adjustment?.promotionCode?.value) {
          promoCodes.push(adjustment.promotionCode.value)
        }
      })
    })

  return {
    coupons: promoCodes,
    currency: order.currency,
    customer: {
      email: order.email,
      first_name: order.billAddress.name.split(' ')[0],
      last_name: order.billAddress.name.split(' ')[1],
    },
    date: order.completedAt,
    discount: Math.abs(parseFloat(order.adjustmentTotal)),
    line_items: lineItems,
    number: order.number,
    shipping: parseFloat(order.shipTotal),
    subtotal: parseFloat(order.total - order.shipTotal - order.taxTotal),
    tax: parseFloat(order.taxTotal),
    total: parseFloat(order.total),
  }
}
