import PropTypes from 'prop-types'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const RowWrapper = ({ children }) => (
  <Flex mb={1} sx={{ justifyContent: 'space-between' }}>
    {children}
  </Flex>
)

const SummaryItems = ({
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  displayTotal,
  displayTotalApplicableStoreCredit,
  displayOrderTotalAfterStoreCredit,
  promotions,
  eligibleForFreeShipping = false,
}) => {
  const translate = useTranslate()
  const isCreditApplied = displayTotalApplicableStoreCredit !== '$0.00'

  return (
    <Box variant="text.body2">
      {displayItemTotal && (
        <RowWrapper>
          <Text>{translate('cart.subtotal')}:</Text>
          <Text>{displayItemTotal}</Text>
        </RowWrapper>
      )}
      {promotions.map(({ id, label, displayAmount }) => {
        return (
          <RowWrapper key={id}>
            <Text>{label}:</Text>
            <Text>{displayAmount}</Text>
          </RowWrapper>
        )
      })}
      {isCreditApplied && (
        <RowWrapper>
          <Text>{translate('cart.footer.credit')}</Text>
          <Text>{displayTotalApplicableStoreCredit}</Text>
        </RowWrapper>
      )}
      {displayTaxTotal && (
        <RowWrapper>
          <Text>{translate('cart.tax')}:</Text>
          <Text>{displayTaxTotal}</Text>
        </RowWrapper>
      )}
      {displayShipTotal && (
        <RowWrapper>
          <Text>{translate('cart.shipping')}:</Text>
          <Text>
            {eligibleForFreeShipping
              ? translate('cart.free_shipping')
              : displayShipTotal}
          </Text>
        </RowWrapper>
      )}
      {displayTotal && (
        <Box
          mt={2}
          pt={2}
          sx={{
            borderTop: '1px solid',
            borderTopColor: 'greyLight',
            fontWeight: 'bold',
          }}
        >
          <RowWrapper>
            <Text>{translate('cart.total')}</Text>
            <Text>
              {isCreditApplied
                ? displayOrderTotalAfterStoreCredit
                : displayTotal}
            </Text>
          </RowWrapper>
        </Box>
      )}
    </Box>
  )
}

SummaryItems.propTypes = {
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  displayTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      displayAmount: PropTypes.string,
    })
  ),
  orderConfirmed: PropTypes.bool,
}

export default SummaryItems
