/** @jsx jsx */

import { Box, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const OrderConfirmationContact = () => {
  const translate = useTranslate()

  return (
    <Box backgroundColor="backgroundMedium" p={3}>
      <Text as="p" variant="title6" mb={2}>
        {translate('confirmation.contact.title')}
      </Text>

      <Text as="p" variant="body2">
        {translate('confirmation.contact.text')}{' '}
        <a href="mailto:support@makerwine.com">support@makerwine.com</a>
        {'.'}
      </Text>
    </Box>
  )
}

export default OrderConfirmationContact
