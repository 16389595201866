/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Heading, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ReferralForm from '~/components/Account/ReferralForm'

const OrderConfirmationReferralPrompt = ({ purl = '' }) => {
  const translate = useTranslate()

  return (
    <Box backgroundColor="backgroundBright" p={3} pb={3}>
      <Heading as="h3" variant="title5" mb={2}>
        {translate('referral.title')}
      </Heading>
      <Text as="p" mb={3} variant="body2">
        {translate('referral.text')}
      </Text>
      <ReferralForm purl={purl} />
    </Box>
  )
}

OrderConfirmationReferralPrompt.propTypes = {
  purl: PropTypes.string.isRequired,
}

export default OrderConfirmationReferralPrompt
