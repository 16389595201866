import { useSelector } from 'react-redux'
import { getSrc } from 'gatsby-plugin-image'
import { getCatalog } from '~/redux/selectors/catalog'
import { createYotpoOrder as createOrder } from '~/services/yotpo/client'
import { isProduction } from '~/utils/misc'

export function useRequestReviews() {
  const catalog = useSelector(getCatalog)

  const createYotpoOrder = (cart) => {
    const products = createYotpoOrderProducts(cart)

    if (products && isProduction()) {
      createOrder({
        currency: cart?.currency,
        date: cart?.updatedAt.split('T')[0],
        email: cart?.email,
        products,
        name: cart?.billAddress?.name,
        number: cart?.number,
      })
    }
  }

  const createYotpoOrderProducts = (cart) => {
    const products = {}

    // For each line item in the cart...
    for (let i = 0; i < cart?.lineItems?.length; i++) {
      const lineItem = cart.lineItems[i]

      if (lineItem.parts) {
        // If it's a kit, does it reference a pack/merch?
        const kit = catalog.kits.find((ck) => ck.sku === lineItem.variant.sku)
        const { disableReviews, reviewListing } = kit

        if (disableReviews) continue

        if (reviewListing?.slug) {
          // If so, create a product for that.
          products[reviewListing?.slug] = createYotpoOrderProduct(
            reviewListing?.slug
          )
        } else {
          // If not, create products for the variants that do.
          lineItem.parts.forEach((part) => {
            const variantListingSlug = getVariantListingSlug(part.sku)

            if (variantListingSlug) {
              products[variantListingSlug] =
                createYotpoOrderProduct(variantListingSlug)
            }
          })
        }
      } else {
        // If it's not a kit, does the variant reference a pack/merch?
        const variantListingSlug = getVariantListingSlug(lineItem.variant.sku)

        if (variantListingSlug) {
          // If so, create a line item for it.
          products[variantListingSlug] =
            createYotpoOrderProduct(variantListingSlug)
        }
      }
    }

    return products
  }

  const createYotpoOrderProduct = (slug) => {
    const listing = [...catalog.packs, ...catalog.merch].find(
      (cl) => cl.slug === slug
    )

    if (listing) {
      const { gtin, mainImage, name, shortDescription, slugUrl } = listing

      const specs = { brand: process.env.GATSBY_CHORD_OMS_BRAND_NAME }

      if (gtin) {
        specs.upc = gtin
      }

      return {
        url: `https://makerwine.com/products/${slugUrl || slug}/`,
        name: name,
        image: getSrc(mainImage),
        description: shortDescription,
        specs,
      }
    }
  }

  const getVariantListingSlug = (sku) =>
    catalog.variants.find((cv) => cv.sku === sku)?.reviewListing?.slug

  return {
    createYotpoOrder,
  }
}
